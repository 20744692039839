<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card outlined class="primary--border">
          <v-card-title class="title">
            Customized Fee Heads Report.
            <v-spacer></v-spacer>

            <v-download-column-select
              v-if="customizedReportForm.items.data.length > 0"
              :downloadColumns="downloadColumns"
              @onDownload="download"
              :showExcel="true"
              :downloadPDFText="'Download PDF Report'"
              :downloadExcelText="'Download Excel Report'"
              >Print Options</v-download-column-select
            >
          </v-card-title>
          <v-card outlined>
            <v-card-title outlined>
              <v-flex>
                <v-fee-head-field
                  v-model="feeHeadId"
                  :inside="true"
                  :with-all="true"
                ></v-fee-head-field>
              </v-flex>

              <v-flex>
                <v-grade-field
                  v-model="gradeId"
                  @selectedGrade="onGradeSelect"
                  :inside="true"
                  :with-all="true"
                ></v-grade-field>
              </v-flex>
              <v-flex>
                <v-section-field
                  :gradeId="gradeId"
                  v-model="sectionId"
                ></v-section-field>
              </v-flex>
              <v-flex>
                <search-button
                  :disabled="!gradeId"
                  permission="subject-read"
                  @action="get()"
                  >Search</search-button
                >
              </v-flex>
            </v-card-title>
          </v-card>
          <br />

          <v-data-table
            :headers="headers"
            :hide-default-footer="customizedReportForm.items.data.length < 1"
            :items="customizedReportForm.items.data"
            :footer-props="footerProps"
            :options.sync="pagination"
            :server-items-length="customizedReportForm.items.meta.total"
            :loading="customizedReportForm.loading"
          >
            <template v-slot:item="{ index, item }">
              <tr>
                <td>{{ index + customizedReportForm.items.meta.from }}</td>
                <td class="text-xs-left">
                  {{ item.student ? item.student.name : "-" }} <br />
                  <small style="color:#666">{{
                    item.student ? item.student.enroll_code : ""
                  }}</small>
                </td>
                <td class="text-xs-right">
                  <strong>{{ item.fee_head ? item.fee_head.title : "-" }}</strong> <br />
                </td>

                <td>
                  {{
                    item.student
                      ? `${item.student.grade} / ${item.student.section}`
                      : "-"
                  }}
                </td>
                <td class="text-xs-right">
                  {{
                    item.student && item.student.roll_no
                      ? item.student.roll_no
                      : "-"
                  }}
                </td>
                <td class="text-xs-left">
                  <strong>{{ item.amount.currency() }}</strong>
                </td>
              </tr>
            </template>
            <v-alert
              slot="no-results"
              :value="true"
              color="error"
              icon="warning"
            >
              Your search for found no results.
            </v-alert>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Form from "@/library/Form";
import Mixins from "@/library/Mixins";
import {
  passDataToExternalDomain,
  redirectDownloadUrl,
} from "../../../../library/helpers";
import VFeeHeadField from "../../../../components/app/VFeeHeadField.vue";

export default {
  mixins: [Mixins],
  components: {
    VFeeHeadField,
  },
  data: () => ({
    feeHeadId: "all",
    downloadColumns: [
      { value: "roll", name: "Roll No.", selected: false },
      { value: "grade_section", name: "Grade / Section", selected: true },
      // { value: "section", name: "Section", selected: true },
      { value: "name", name: "Student Name", selected: true },
      { value: "fee_head", name: "Fee Head", selected: true },
      {
        value: "amount",
        name: "Amount",
        selected: true,
      },
    ],
    customizedReportForm: new Form({}, "/api/customized-fee/report"),
    headers: [
      { text: "#", align: "center", value: "id", width: 50, sortable: false },
      { text: "Student", align: "left", value: "title", sortable: false },
      { text: "Fee Head", align: "left", value: "fee_head", sortable: false },
      {
        text: "Grade / Section",
        align: "left",
        value: "grade",
        sortable: false,
      },
      {
        text: "Roll No",
        align: "left",
        value: "title",
        sortable: false,
        width: 150,
      },
      {
        text: "Amount",
        align: "left",
        value: "amount",
        sortable: false,
        width: 200,
      },
    ],
    gradeId: "",
    sectionId: "",
    sections: [],
    pagination: {
      itemsPerPage: 10,
    },
    download_url: "",
  }),
  watch: {
    pagination: function() {
      this.get();
    },
    gradeId: function() {
      this.sectionId = "";
      this.customizedReportForm.items.data = [];
    },
    sectionId: function() {
      this.customizedReportForm.items.data = [];
    },
  },
  computed: {},
  mounted() {},
  methods: {
    onGradeSelect(grade) {
      this.sections = grade.sections ? grade.sections.map(item => {
        return item.id;
      }) : [];
    },
    get() {
      if (!this.gradeId) {
        return;
      }
      if(!this.sectionId) {
        this.sectionId = this.sections.join(',');
      }
      let query = this.queryString(
        `gradeId=${this.gradeId}&sectionId=${this.sectionId}&feeHeadId=${this.feeHeadId}`
      );
      this.customizedReportForm
        .get(null, query)
        .then((res) => {
          this.download_url = res.data.download_url;
        })
        .catch((err) => {});
    },

    download({ columnString, type }) {
      let columns = [];
      this.downloadColumns.map((item) => {
        if (item.selected === true) columns.push(item.value);
      });

      if (columns.length > 0) {
        if(!this.sectionId) {
          this.sectionId = this.sections.join(',');
        }
        passDataToExternalDomain("reporting");
        setTimeout(() => {
          redirectDownloadUrl({
            uri: "/download/billing/customized-fee-head-report",
            queryString: `gradeId=${this.gradeId}&sectionId=${this.sectionId}&feeHeadId=${this.feeHeadId}&type=${type}&columns=${columnString}`,
          });
        }, 300);
      }
    },
  },
};
</script>
<style>
.rate {
  position: absolute;
  margin-top: -20px;
  margin-left: 10px;
  color: gray;
  font-size: 12px;
}
.v-input--selection-controls .v-input__slot > .v-label,
.v-input--selection-controls .v-radio > .v-label {
  font-size: 14px !important;
}
.v-menu__content .v-input--selection-controls {
  margin-top: 0 !important;
}
</style>
